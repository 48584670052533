@import './storage-modal';

.storage {
    button {
        border: none;

        &.white {
            background-color: white;
            margin-right: 20px;
            color: #44526C;
            border: 1px solid #DFE1E6;
            font-weight: 600;
        }
    }

    .modal-content.thank-you {
        display: none;
        text-align: center;
        max-width: 400px;
        margin: 0 auto;

        &.show {
            display: block;
        }

        .modal-header {
            justify-content: center;
        }

        p {
            font-size: 14px;
            width: 162px;
            margin: 0 auto;
        }

        .modal-footer {
            justify-content: center;
        }
    }

    .modal-header {
        padding: 1rem 40px;
        border-bottom: 10px;
        align-items: center;
        padding-bottom: 0;
        padding-top: 30px;

        h5 {
            font-weight: bold;
            font-size: 20px;
        }
    }

    .modal-body {
        padding: 0 40px;
        padding-bottom: 30px;
        padding-top: 17px;

        .request-type {
            width: 33px;
            margin-bottom: 17px;

            img {
                width: 35px;
            }
        }
    }

    .modal-footer {
        padding: 14px 40px;
    }

    form {
        label {
            font-weight: 600;
            font-size: 12px;
            color: $blueBlack;
            margin-bottom: 6px;
        }

        input, select {
            font-size: 13px;
        }
    }

    button.request {
        margin-right: 20px;
        border: 0;
        outline: 0;
        background: white;
        color: #44526C;
        border: 1px solid #DFE1E6;
        font-weight: 600;
        padding-left: 3px;

        img {
            width: 16px;
            height: 15px;
            margin-right: 9px;
        }
    }

    .request-cancel {
        color: #283856!important;
    }


    .btn-filled-rounded {
        font-size: 12px;
        display: flex;
        align-items: center;
        font-weight: 500;

        img {
            margin-left: 13px;
        }
    }

    .head {
        .row > div {
            display: flex;
        }

        .right-part {
            justify-content: flex-end;
        }

        .search-wrapper {
            display: flex;
            align-items: center;

            form {
                display: flex;
                align-items: center;
            }

            h3 {
                font-weight: 700;
                font-size: 20px;
                margin-right: 32px;
                margin-bottom: 0;
            }

            input {
                width: 400px;
            }

            .btn.search {
                height: 32px;
                line-height: 1;
                border-radius: 6px;
                border: none;
                outline: none;
                padding: 0 25px;
                margin-left: 10px;
                background: #1e77bd;
                font-size: 13px;
                font-weight: 500;
                color: white;
                border-radius: 50px;
                font-size: 12px;
            }

            .btn.reset {
                height: 38px;
                border-radius: 6px;
                border: none;
                outline: none;
                padding: 0 20px;
                margin-left: 10px;
                background: white;
                font-size: 13px;
                font-weight: 500;
                border: 1px solid #f15057;
            }
        }

        .select-wrapper {
            display: flex;
            align-items: center;

            select {
                font-family: "Gotham SSm A", "Gotham SSm B";
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: $blueBlack;
                border: none;
                background: transparent;
                padding-right: 6px;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: '';
                outline: none;
                padding: 10px;
            }

            img {
                width: 11px;
            }
        }

        .col-4 {
            display: flex;
            justify-content: flex-end;
        }
    }

    .documents-wrapper {
        background: white;
        padding: 26px 32px;
        padding-bottom: 35px;
        margin-top: 30px;
        border: 1px solid #EBECF0;
        box-sizing: border-box;
        border-radius: 8px;

        .document {
            &--restricted {
                a, button {
                    filter: grayscale(1);
                }

                cursor: no-drop;

                a {
                    pointer-events: none;
                    cursor: no-drop;
                }
            }
        }

        &.grid {
            .document {
                width: 100%;
                overflow-wrap: anywhere;
            }
        }

        .document-nav {
            .breadcrumbs {
                .breadcrumbs__item {
                    margin-right: 3px;

                    &:after {
                        font-size: 12px;
                        color: #7C8698;
                        margin-left: 6px;
                    }

                    &:last-child {
                        .breadcrumbs__item__link {
                            color: #000;
                        }
                    }
                }

                .breadcrumbs__item__link {
                    font-weight: 600;
                    font-size: 12px;
                    color: #7C8698;
                }
            }

            .nav-tabs {
                border-bottom: none;

                li {
                    a {
                        border-bottom: 2px solid transparent;
                        position: relative;
                        bottom: -1px;
                        padding-bottom: 15px;
                        display: inline-block;
                        font-size: 13px;
                        margin-right: 24px;
                        color: #44527C;
                        font-weight: 600;
                        text-decoration: none;
                        margin-top: 12px;

                        &:hover {
                            border-bottom: 2px solid #c6c6c6;
                        }

                        &.active {
                            border-bottom: 2px solid #0F75BC;
                            color: #0F75BC;
                        }
                    }
                }
            }
        }

        .tools {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dee2e6;
            margin-left: -32px;
            padding-left: 32px;
            margin-right: -32px;
            padding-right: 32px;

            .right {
                display: flex;
                align-items: center;
                position: relative;
                top: -1px;

                select {
                    border: none;
                    background: none;
                    outline: none;
                    font-weight: 600;
                    color: $blueBlack;
                    margin-right: 20px;
                }

                .documents-view {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-left: 2px solid #e0e0e0;
                    height: 19px;
                    padding-left: 19px;

                    span {
                        width: 21px;
                        height: 21px;
                        background-color: #7C8698;
                        display: block;
                        cursor: pointer;

                        &.grid-view {
                            mask: url("../../assets/icons/grid.svg") no-repeat center / contain;
                            -webkit-mask: url("../../assets/icons/grid.svg") no-repeat center / contain;
                        }

                        &.list-view {
                            mask: url("../../assets/icons/list-view.svg") no-repeat center / contain;
                            -webkit-mask: url("../../assets/icons/list-view.svg") no-repeat center / contain;
                            width: 24px;
                            height: 24px;
                            position: relative;
                            margin-left: 7px;
                        }

                        &:hover {
                            background-color: #1E8BD8;
                        }

                        &.active {
                            background-color: #1E8BD8;
                        }
                    }
                }
            }

        }

        &.list {
            .documents {
                display: block;

                .document {
                    padding: 6px;
                    position: relative;
                    height: 54px;
                    display: block;
                    background: none;
                    box-shadow: none;
                    border: none;
                    border-bottom: 1px solid #ededed;
                    border-radius: 0;

                    &:first-child {
                        border-top: 1px solid #ededed;
                    }

                    img.file-ico {
                        width: 26px;
                        height: 26px;
                        display: block;
                        margin: 0;
                        margin-right: 25px;
                    }

                    img.download-ico {
                        margin-right: 0;
                        position: static;
                        width: 23px;
                        height: 23px;
                    }

                    .row-edits {
                        position: static;
                        top: unset;
                        right: unset;
                    }

                    &.new {
                        display: flex;
                        text-align: left;
                        justify-content: flex-start;

                        img {
                            width: 26px;
                            height: 26px;
                            display: block;
                            margin: 0;
                            margin-right: 25px;
                        }

                        .content {
                            text-align: left;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .content {
                        text-align: left;
                        display: grid;
                        grid-template-columns: 6fr 2fr 2fr;
                        justify-content: center;
                        align-items: center;

                        > a {
                            display: flex;
                            width: 100%;
                            align-items: center;
                        }

                        .texts {
                            display: grid;
                            grid-template-columns: 5fr 3fr 2fr;
                            width: 100%;
                            align-items: center;
                        }

                        .author {
                            order: 0;
                            flex-direction: unset;
                            justify-content: unset;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .documents {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 23px;

        &.list {
            display: block;

            .document {
                padding: 6px;
                position: relative;
                height: 54px;
                display: block;
                background: none;
                box-shadow: none;
                border: none;
                border-bottom: 1px solid #ededed;
                border-radius: 0;

                &:first-child {
                    border-top: 1px solid #ededed;
                }

                img {
                    width: 26px;
                    height: 26px;
                    display: block;
                    margin: 0;
                    margin-right: 25px;
                }

                &.new {
                    display: flex;
                    align-items: center;

                    img {
                        width: 26px;
                        height: 26px;
                        display: block;
                        margin: 0;
                        margin-right: 25px;
                    }

                    .content {
                        text-align: left;
                        display: grid;
                        grid-template-columns: 6fr 2fr 2fr;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .content {
                    > a {
                        display: flex;
                        width: 100%;
                        align-items: center;
                    }

                    .texts {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        width: 100%;
                        align-items: center;
                    }
                }
            }
        }

        .document {
            display: grid;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #F4F5F7;
            box-sizing: border-box;
            box-shadow: 0px 3px 4px rgba(13, 31, 64, 0.03);
            border-radius: 8px;
            padding: 24px;
            position: relative;

            .star-btn {
                background: none;
                outline: none;
                position: relative;
                right: -4px;
                top: -1px;
            }

            .star {
                width: 23px;
                height: 23px;
                background-color: #B4BAC4;
                display: block;
                cursor: pointer;
                position: relative;
                top: 3px;
                mask: url("../../assets/icons/star_empty.svg") no-repeat center / contain;
                -webkit-mask: url("../../assets/icons/star_empty.svg") no-repeat center / contain;
                margin-right: -3px;

                &.full {
                    mask: url("../../assets/icons/star.svg") no-repeat center / contain;
                    -webkit-mask: url("../../assets/icons/star.svg") no-repeat center / contain;
                    position: relative;
                    background-color: #F7C544;
                }

                &:hover {
                    background-color: #F7C544;
                    mask: url("../../assets/icons/star.svg") no-repeat center / contain;
                    -webkit-mask: url("../../assets/icons/star.svg") no-repeat center / contain;
                    position: relative;
                }
            }

            .row-edits {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
                position: absolute;
                top: 9px;
                right: 12px;

                .dropdown-toggle::after {
                    display: none;
                }

                .dropdown-menu {
                    padding: 10px !important;
                    border-radius: 12px;
                    overflow: hidden;

                    &.show {
                        padding: 10px;
                        display: grid;
                    }

                    button {
                        width: 100%;
                    }

                    .dropdown-item {
                        margin: 0;
                        padding: 5px 10px;
                        font-size: 13px;
                        margin: 0;
                        cursor: pointer;
                    }

                    img {
                        width: 17px;
                        height: 17px;
                        margin-right: 11px;
                    }
                }

                .dotts {
                    width: 23px;
                    height: 23px;
                    background: white;
                    border: 1px solid #DFE1E6;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 15px;
                    font-weight: 600;
                    padding: 0;
                    padding-top: 3px;

                    &:hover {
                        border: 1px solid #a5a5a5;
                    }

                    img {
                        margin: 0;
                        width: 12px;
                        margin-top: -2px;
                    }
                }
            }

            img {
                width: 40px;
                height: 40px;
            }

            .content {
                text-align: left;
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 13px;
                    font-weight: 600;
                    margin-top: 12px;
                    margin-bottom: 11px;
                    color: #333;
                }

                .download-ico, .preview-ico {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 17px;
                    right: 14px;
                }

                .preview-ico {
                    right: 50px;
                    height: 21px;
                    width: 16px;
                }

                .added, .created, .author {
                    font-size: 12px;
                    margin-bottom: 0px;
                    color: #000;

                    b {
                        font-weight: 600;
                        color: #44526C;
                    }

                    span {
                        font-weight: 300;
                        padding-left: 5px;
                    }
                }

                .author {
                    display: flex;
                    order: 3;
                    flex-direction: row-reverse;
                    margin: 0;
                    justify-content: flex-end;
                    margin-top: 7px;

                    img {
                        height: 21px;
                        width: 19px;
                        position: relative;
                        left: -4px;
                        top: -1px;
                        margin-top: 0px;
                    }

                    p {
                        font-weight: 400;
                        color: $blueBlack;
                        margin-bottom: 0;
                        font-size: 12px;
                    }
                }
            }

            &.new {
                justify-content: center;

                .content {
                    text-align: center;
                    display: block;

                    .title {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                img {
                    width: 77px;
                    height: 77px;
                    margin-bottom: 14px;
                }
            }
        }
    }

}

.input-wrapper {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;

    .in-search {
        background-color: transparent;
        border: 0;
        box-shadow: 0;
    }

    .reset-button {
        padding: 0px 10px;
        color: #999;
        font-size: 16px;

        &:hover {
            color: #3490dc
        }
    }
}

@media screen and (max-width: 991px) {
    .storage .documents {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .storage .head .search-wrapper input {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .storage .documents {
        grid-template-columns: 1fr;
    }
    .storage .documents-wrapper.list .documents .document .content.fav {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .storage {
        .documents-wrapper {
            margin-left: -15px;
            margin-right: -15px;
            padding: 20px;
            overflow: hidden;

            &.list {
                .documents .document .content .author {
                    display: flex;
                    order: 3;
                    flex-direction: row-reverse;
                    margin: 0;
                    justify-content: flex-end;
                    margin-top: 7px;

                    img {
                        height: 21px;
                        width: 19px;
                        position: relative;
                        left: -4px;
                        top: -1px;
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    .storage .head .right-part {
        margin-top: 10px;
        justify-content: space-between;
    }
    .search-wrapper {
        width: 100%;
    }
    .storage .head .search-wrapper form {
        width: 100%;
        display: block;

        .input-wrapper {
            margin-bottom: 10px;
        }

        .btns {
            button {
                margin: 0 !important;
                width: 100%;
            }
        }
    }
    .storage .documents-wrapper.list .documents .document .content {
        display: block;
    }
    .storage .documents-wrapper.list .documents .document {
        height: auto;
        padding-top: 10px;
    }
    .storage .documents-wrapper.list .documents .document .content > a {
        display: block;
    }
    .storage .documents-wrapper.list .documents .document .row-edits {
        position: absolute;
        top: 10px;
        right: 0;
    }
}

@media screen and (max-width: 570px) {
    .storage {
        .documents-wrapper.list {
            .documents .document .content .title {
                margin-bottom: 0;
            }

            .documents .document {
                height: auto;
                min-height: 66px;
            }

            .documents .document .content .texts {
                grid-template-columns: 1fr;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .storage .documents {
        gap: 10px;
    }
}

@media screen and (max-width: 430px) {
    .storage {
        .documents-wrapper {
            margin-left: -15px;
            margin-right: -15px;
            padding: 20px;
            overflow: hidden;

            &.list {
                .documents .document img.download-ico {
                    margin-right: 0;
                    top: 20px;
                }
            }
        }
    }
}


//HIGHLIHGTS
.container.highlights {
    max-width: 1312px;
}

.storage.highlights {
    .btn:focus, .btn.fos {
        box-shadow: unset;
    }

    #accordion {
        margin-top: 28px;

        .acc-el {
            margin-bottom: 16px;
        }

        .card-header:first-child {
            background: #FFFFFF;
            border: 1px solid #EBECF0;
            box-sizing: border-box;
            box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
            border-radius: 8px;
            padding: 18px;

            button {
                padding: 0;
                display: flex;
                align-items: center;

                font-size: 14px;
                line-height: 17px;
                display: flex;
                align-items: center;
                font-weight: bold;
                color: #44526C;

                img {
                    margin-right: 15px;
                }
            }
        }

        .card-body {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 80px;
            padding-left: 54px;

            p {
                font-size: 14px;
                line-height: 150%;
                display: flex;
                align-items: center;
                color: #44526C;
            }
        }
    }

    .bottom-buttons {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .btn-left {
            background: transparent;
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #0F75BC;
            font-weight: bold;
            font-size: 12px;
            padding: 0;

            img {
                margin: 0;
                margin-right: 13px;
            }
        }
    }

    .steps {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 70px;

        .progress {
            width: 100%;
            height: 3px;
            background-color: #EBECF0;

            &.done {
                background-color: #5DB082;
            }

            &.in-progress {
                background-color: #0F75BC;
            }
        }

        .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            p {
                font-weight: bold;
                font-size: 9px;
                text-transform: uppercase;
                color: #B4BAC4;
                margin-top: 12px;
                margin-bottom: 3px;
                position: absolute;
                top: 31px;
                width: 100px;
                left: 0;
            }

            b {
                font-weight: bold;
                font-size: 14px;
                color: #44526C;
                position: absolute;
                top: 60px;
                width: 145px;
                left: 0;
            }

            .circle {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }

            &.done {
                .circle {
                    background: #5DB082;
                }
            }

            &.in-progress {
                b {
                    color: #0D1F40;
                }

                .border-circle {
                    border: 1px solid #0F75BC;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin-bottom: -4px;
                }

                .circle {
                    background: #0F75BC;
                    font-weight: bold;
                    font-size: 16px;
                    color: white;
                }
            }

            &.grey {
                b {
                    color: #98A0AE;
                }

                .circle {
                    border: 2px solid #B4BAC4;
                    font-size: 16px;
                    text-align: center;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #98A0AE;
                }
            }


            &:last-of-type {

                b {
                    left: auto;
                    text-align: right;
                    right: 0;
                    width: 120px;
                }

            }
        }
    }

    .documents-wrapper {
        &.list {
            .document-nav {
                h5 {
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }

}

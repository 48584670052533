.container.reports {
    background: #FFFFFF;

    border: 1px solid #F4F5F7;
    box-sizing: border-box;

    box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
    border-radius: 8px;
    padding: 0;
    .experiment-top-nav {
        margin: 0;
        padding: 22px 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
            width: unset;
        }
        .tools {
            display: flex;
            align-items: center;
            .add-labs, .add-tags {
                display: flex;
                gap: 12px;
                position: relative;
                padding-right: 18px;
                margin-right: 10px;
                &:after {
                    content: "";
                    width: 2px;
                    border-right: 2px solid #ebebeb;
                    position: absolute;
                    right: 0;
                    top: 8px;
                    bottom: 8px;
                    display: flex;
                    align-items: center;
                }
                h4 {
                    font-weight: 500;
                    font-size: 13px;
                    width: auto;
                    display: flex;
                    align-items: center;

                    color: #44526C;
                }
                .added, .add {
                    display: flex;
                    gap: 10px;
                    position: relative;
                    cursor: pointer;
                    .lab, .tag {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 6px 14px;
                        height: 30px;
                        left: 0px;
                        top: 0px;
                        background: #E7F5FF;
                        border-radius: 4px;
                        position: relative;
                        img {
                            position: absolute;
                            right: -7px;
                            cursor: pointer;
                            width: 16px;
                            height: 16px;
                        }
                        p{
                            font-weight: 500;
                            font-size: 12px;

                            display: flex;
                            align-items: center;
                            color: #1666A0;
                            margin: 0px;
                        };
                    }
                }
                .select-lab, .select-tag {
                    position: absolute;
                    top: 34px;
                    right: 0;
                    width: max-content;
                    background: #FFFFFF;
                    border: 0.5px solid #EBECF0;
                    box-sizing: border-box;
                    box-shadow: 0px 3px 4px -1px rgb(13 31 64 / 3%);
                    border-radius: 6px;
                    padding: 16px 18px;
                    z-index: 99;
                    label {
                        font-size: 12px;
                        line-height: 11px;
                        color: #606C82;
                        font-weight: 400;
                    }
                }
            }
            .date {
                display: flex;
                align-items: center;
                justify-content: space-between;
                img {
                    margin-right: 5px;
                }
                input {
                    background: none;
                    border: none;
                    width: 87px;
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 0.02em;
                    color: #0F75BC;
                    outline: none;
                }
                span {
                    border-bottom: 2px solid #0F75BC;
                    height: 2px;
                    width: 13px;
                    display: block;
                    margin-right: 10px;
                }
            }
        }
    }
}

.reports-table {
    .t-header {
        display: grid;
        grid-template-columns: 2fr 4fr 2fr 7fr 1fr;
        height: 48px;
        align-items: center;
        border-top: 1px solid #EBECF0;
        border-bottom: 1px solid #EBECF0;
        //padding-right: 4px;
        p {
            font-weight: 400;
            font-size: 10px;
            text-transform: uppercase;
            color: #7C8698;
            margin: 0;
            margin-left: 24px;
            border-right: 1px solid #EBECF0;
            height: 100%;
            align-items: center;
            display: flex;
            &:last-child {
                border-right: 0px;
            }
        }
        .mini {
            margin-left: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-right: 1px solid #EBECF0;
            height: 100%;
            .mini-group {
                display: flex;
                gap: 20px;
                margin-right: 20px;
                .circle {
                    width: 12px;
                    height: 12px;
                    background: #0F75BC;
                    border-radius: 50%;
                    display: block;
                }
                .square {
                    width: 12px;
                    height: 12px;
                    background: #837AD3;
                    border-radius: 2px;
                    display: block;
                }
                .triangle {
                    display: block;
                    width: 13px;
                    height: 13px;
                    background: url(../../assets/icons/triangle.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .completed {
                    display: block;
                    width: 13px;
                    height: 13px;
                    background: url(../../assets/icons/completed.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .legend-item {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    p {
                        font-weight: normal;
                        color: #44526C;
                        text-transform: capitalize;
                    }
                }
            }
            p {
                margin-left: 0;
                height: 100%;
                align-items: center;
                display: flex;
                border-right: 0px;
            }
        }
    }
    .t-body {
        position: relative;
        /* width */
        &::-webkit-scrollbar {
            width: 4px;
            position: absolute;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #EBECF0;
        }
        .t-row {
            display: grid;
            grid-template-columns: 2fr 4fr 2fr 7fr 1fr;
            border-bottom: 1px solid #EBECF0;
            &:last-child {
                border-bottom: 0px;
            }
            &:nth-last-child(2) {
                margin-bottom: 26px;
            }
            &.bottom {
                position: sticky;
                bottom: 0;
                height: 43px;
                background: white;
                width: 100%;
                border-top: 1px solid #EBECF0;
                z-index: 99;
                .mo {
                    display: flex;
                    justify-content: space-between;
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-top: 5px;
                    padding-right: 10px;
                    padding-left: 10px;
                    span {
                        font-weight: 400;
                        font-size: 10px;
                        //text-align: center;
                        color: #98A0AE;
                    }
                }
            }
            h5 {
                font-weight: 400;
                font-size: 13px;
                color: #0D1F40;
            }
            .sq {
                margin-left: 24px;
                border-right: 1px solid #EBECF0;
                height: 100%;
                align-items: center;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 14px;
                padding-right: 14px;
                font-size: 12px;
                color: #44526C;
                &.name {
                    font-weight: 500;
                    font-size: 13px;
                    color: #0D1F40;
                }

                &:last-child {
                    border-right: 0px;
                    border-left: 1px solid #EBECF0;
                    margin-left: 0;
                    padding-left: 20px;
                }

                &.time {
                    position: relative;
                    margin-right: 24px;
                    border-right: 0;
                    padding-right: 0;
                    padding-top: 18px;
                    .progress-time {
                        display: flex;
                        width: 100%;
                        height: 20px;
                        //border-top: 2px dashed #63BDFB;
                        position: relative;
                        left: -6px;

                        .dashed-line{
                            border-top: 2px dashed #63BDFB;
                            width: 100%;
                            margin-top: -1px;
                        }

                        span {
                            cursor: pointer;
                            width: 12px;
                            height: 12px;
                            top: -7px;
                            left: 0;
                            position: absolute;
                            z-index: 9;
                            &.duration {
                                height: 3px;
                                border-top: 3px solid #63BDFB;
                                top: -2px;
                                z-index: 8;
                            }
                            &.start {
                                background: #0F75BC;
                                border-radius: 50%;
                            }
                            &.scheduled-end {
                                background: #837AD3;
                                border-radius: 2px;
                            }
                            &.expected {
                                width: 13px;
                                height: 13px;
                                background: url(../../assets/icons/triangle.svg);
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                            &.completed {
                                width: 13px;
                                height: 13px;
                                background: url(../../assets/icons/completed.svg);
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-color: white;
                            }
                        }
                    }
                    .bg-lines {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: space-between;
                        position: absolute;
                        top: 0;
                        .line {
                            height: 100%;
                            display: flex;
                            border-right: 1px dashed gainsboro;
                        }
                    }
                }

                &.open{

                    a{
                        font-weight: 500;
                        font-size: 12px;
                        color: #0F75BC;
                        position: relative;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        &:after {
                            content: '';
                            display: block;
                            width: 8px;
                            height: 8px;
                            background: url(../../assets/icons/arrow-g.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
}

.file-detail-modal {
    .modal-dialog {
        width: 90%;
        height: 90vh;
        max-width: initial;

        .modal-content {
            height: 100%;

            .file-detail-modal-view {
                width: 100%;
                height: 100%;
            }
        }
    }

    .modal-body {
        height: 100% !important;
    }
}




#user_permissions{
    display: flex;
    align-items: center;
    position: relative;
    .plus{
        font-size: 17px;
        font-weight: 400;
        background: $lightGrey;
        width: 32px;
        height: 32px;
        text-align: center;
        color: #42526E;
        border-radius: 50%;
        border: 2px solid white;
        align-items: center;
        justify-content: center;
        padding: 10px;
        display: flex;
    }
    .results{

        list-style: none;
        padding: 0;
        position: absolute;
        left: 0;
        top: 42px;
        width: 100%;

        li{
            padding: 0;
            cursor: pointer;
            font-weight: bold;
            position: relative;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $lightBlue;
            color: #0F75BC;
            z-index: 999;

            &:hover{
                color: black;
            }

            &:after{
                content: "\002B";
                position: absolute;
                right: 8px;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &.exists{
                color: #739bbf;
                cursor: default;

                &:after{
                    content: "\2713";
                }

                &:hover{
                    color: unset;
                }

            }

        }

    }
}
.btn-edit-exp{
    background-color: #FFFFFF;
    border: 1px solid #DFE1E6;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
    border-radius: 100px;
    color: #44526C;
    padding: 2px 18px;
    margin-left: 20px;
    outline: none;
    &:hover{
        background-color: #f7f7f7;
    }
}

.btn-delete-exp{
    background-color: #B80303;
    border: 1px solid #A60A0A;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
    border-radius: 100px;
    color: #FFF;
    padding: 2px 18px;
    margin-left: 20px;
    outline: none;

    &:hover{
        background-color: #CA1515;
        color: #FFF;
    }
}

.form-group.add-rm-users{
    display: flex;
    margin: 0;
    align-items: center;
    input{
        border-radius: 20px;
        height: 31px;
        padding: 0;
        padding-left: 10px;
    }
    .close-input{
        background: url(../../assets/icons/close.svg);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #363129;
        border-radius: 50%;
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        padding: 12px;
        position: absolute;
        right: 5px;
        cursor: pointer;
    }
}
.usrs-groups{
    height: 39px;
    display: flex;
    align-items: center;
    position: relative;
    img{
        width: 32px;
        border-radius: 50%;
        border: 2px solid white;
    }
    .user{
        right: 0;
        text-decoration: none;
        position: absolute;

        &.toslide{
            -webkit-transition: margin-top 0.3s;
            -moz-transition: margin-top 0.3s;
            -ms-transition: margin-top 0.3s;
            -o-transition: margin-top 0.3s;
            transition: margin-top 0.3s;

            &:hover{
                margin-top: -27px;
            }

        }

    }
}
.experiment-title-wrapper{
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
            margin-right: 16px;
        }
        .in-search{
            margin-right: -15px;
            min-width: 260px;
        }
        .group{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .badge{
            background: $lightBlue;
            color: #0F75BC;
        }
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 26px;
        p{
            margin: 0;
            font-size: 13px;
        }
    }
}
.experiment-top-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    gap: 20px;
    h4{
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        width: 250px;
    }
    #filterControl{
        width: 180px;
    }
    #exp-search{
        width: 494px;
    }
    .form-group{
        margin: 0;
    }
    select, button{
        height: 32px;
    }
    select{
        background: #FFFFFF;
        border: 1px solid #EBECF0;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 20px;
    }
    .spacer{
        height: 24px;
        border-right: 2px solid #DFE1E6;
        display: block;
    }
    .btn{
        background-color: #FFFFFF;
        border: 0.5px solid #DFE1E6;
        box-sizing: border-box;
        box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
        border-radius: 100px;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #44526C;
        height: 32px;
        padding: 0 16px;
        outline: none;
        &.new-exp{
            background-color: #0F75BC;
            color: white;
            border: 1px solid transparent;
            &:hover {
                color: #fff;
                background-color: #227dc7;
            }
            img{
                padding-right: 6px;
            }
        }
    }
}
.experiment-labels{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    select{
        margin: 0;
        background: none;
        border: 0;
        padding-left: 0;
        font-size: 12px;
        color: #44526C;
        font-weight: bold;
    }
    .labels{
        .label{
            width: 122px;
            height: 30px;
            background: #E7F5FF;
            border-radius: 4px;
            padding: 8px 14px;
            position: relative;
            margin-right: 16px;
            font-size: 12px;
            font-weight: bold;
            color: #1666A0;
            img{
                position: absolute;
                right: -8px;
                cursor: pointer;
            }
        }
    }
}
.experiments-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 21px;
    padding-bottom: 38px;
    .prev-experiment{
        padding: 21px 24px;
        padding-bottom: 22px;
        background: #FFFFFF;
        border: 1px solid #F4F5F7;
        box-sizing: border-box;
        box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
        border-radius: 8px;
        position: relative;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        &.add-result{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            a{
                border: none;
                background: none;
                outline: none;
                text-align: center;
                text-decoration: none;
            }
            h4{
                color: #44526C;
                font-size: 19px;
                font-weight: 400;
                color: #44526C;
            }
            p{
                font-size: 52px;
                line-height: 47px;
                color: #44526C;
            }
        }
        .edit{
            cursor: pointer;
            width: 23px;
            height: 23px;
            position: absolute;
            background: white;
            border: 1px solid #e7e7e7;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 10px;
            top: 10px;
            &:hover{
                border: 1px solid #8e8e8e;
            }
            img{
                width: 15px;
                opacity: 0.7;
            }
        }
        hr{
            margin: 12px 0;
        }
        .title{
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 12px;
        }
        .group, .start-end{
            p{
                font-size: 13px;
                color: #0D1F40;
                margin-bottom: 4px;
                span{
                    color: #606C82;
                }
            }
        }
        .start-end{
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            p{
                margin: 0;
            }
        }
        .comment{
            .latest{
                font-size: 13px;
                color: #0D1F40;
                margin-bottom: 8px;
            }
            .title{
                color: #0D1F40;
                font-size: 11px;
                margin-bottom: 6px;
            }
            .content{
                font-style: italic;
                font-weight: normal;
                font-size: 12px;
                color: #44526C;
                margin-bottom: 9px;
                a{
                    font-size: 11px;
                    line-height: 13px;
                    color: #0F75BC;
                    font-style: normal;
                    font-weight: bold;
                    padding-left: 5px;
                }
            }
        }
        .btn{
            background-color: #FFFFFF;
            border: 1px solid #DFE1E6;
            box-sizing: border-box;
            box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
            border-radius: 100px;
            color: #44526C;
            padding: 2px 18px;
            margin-top: auto;
            width: 72px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                background-color: #f4f4f4;
            }
        }
    }
    .prev-lab{
        padding: 21px 24px;
        padding-bottom: 22px;
        background: #FFFFFF;
        border: 1px solid #F4F5F7;
        box-sizing: border-box;
        box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
        border-radius: 8px;
        min-height: 307px;
        p.title{
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            color: #0D1F40;
            margin-bottom: 5px;
        }


        p.subtitle{
            font-weight: bold;
            font-size: 13px;
            line-height: 150%;
            color: #0D1F40;
            margin-bottom: 5px;
        }

        span{
            font-weight: normal;
            font-size: 12px;
            color: #0D1F40;
            margin-bottom: 6px;
            display: block;
        }
        ul{
            padding-left: 15px;
            li{
                font-size: 12px;
                font-weight: bold;
                color: #0F75BC;
                margin-bottom: 3px;
                a{
                    font-weight: bold;
                }
            }
        }
    }
}


// new
.create-experiment, .edit-experiment{

    .btn{
        border-radius: 42px;
        font-weight: 500;
        width: 160px;
        height: 34px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

}


#science-experiment-result{

    //p, label, a, td, th{
    //    font-weight: 500;
    //}

}


//SIGNATURE
.signature-row {
    justify-content: flex-end;
    img {
        padding: 0px 45px;
    }
    hr {
        border-top: 2px solid #7C8698;
        margin-bottom: 5px;
        margin-top: 7px;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        b{
            padding-left: 4px;
        }
    }
}

//DATA ROOM
.data-room {
    margin-top: 40px;
    /* width */
    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 14px;
        background-color: white;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: white;
    }

    ::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #DFE1E6;
    }
    .date-range-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        border: 1px solid #3490dc;
        border-radius: 12px;
        padding: 3px 12px;
        padding-top: 4px;
        background: white;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.025) !important;
        border: 1px solid #eee;
        .arrow {
            width: 14px;
            height: 14px;
        }
    }
    .companies {
        display: flex;
        justify-content: center;
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            border-radius: 12px;
            padding: 3px 12px;
            padding-top: 4px;
            background: white;
            border: 1px solid #eee;
            font-weight: 500;
            font-size: 12px;
            text-align: left;
            letter-spacing: 0.02em;
            box-shadow: 0 0.125rem 0.15rem rgba(0, 0, 0, 0.025) !important;
            color: #44526C;
            width: unset;
        }
        p {
            margin: 0;
        }
        .radio-item:checked ~ label {
            background: #4c98cc;
            color: white;
        }
        input {
            opacity: 0;
            display: none;
        }
        .dropdown-menu {
            top: 4px!important;
            background-color: #ffffff;
            min-width: 160px;
            z-index: 9999;
            border: none;
            align-items: baseline;
            justify-content: center;
            border-radius: 10px;
            box-shadow: 0px 3px 50px rgb(4 15 34 / 13%);
            padding: 21px 0px;
            flex-direction: column;
            gap: 10px;
            left: unset!important;
            right: unset;
            width: max-content;
            label {
                margin-bottom: 0;
                cursor: pointer;
                font-weight: 500;
                font-size: 14px;
                text-align: left;
                letter-spacing: 0.02em;
                padding: 0 21px;
                width: 100%;
                &:hover {
                    background: #4c98cc;
                    color: white;
                }
            }
            p{
                margin-bottom: 0;
                font-weight: 500;
                font-size: 14px;
                text-align: left;
                letter-spacing: 0.02em;
                padding: 0 21px;
                width: 100%;
            }
        }
    }
    .filters {
        form {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }
        button {
            border-radius: 12px;
            padding-left: 20px;
            padding-right: 20px;
            box-shadow: 0 0.125rem 0.15rem rgba(0, 0, 0, 0.025) !important;
        }
    }
    #drRangeSelector {
        background: none;
        border: none;
        outline: none;
        font-weight: 500;
        font-size: 12px;
        text-align: left;
        letter-spacing: 0.02em;
        color: #0F75BC;
        width: 200px;
        position: relative;
        background: url(../../assets/icons/arrow_down_c.svg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: 14px;
        &.active {
            background: url(../../assets/icons/arrow_down_c_r.svg);
            background-repeat: no-repeat;
            background-position: right;
            background-size: 14px;
        }
        &::-webkit-input-placeholder {
            color: #44526C;
        }
        &:-moz-placeholder {
            color: #44526C;
            opacity: 1;
        }
        &::-moz-placeholder {
            color: #44526C;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: #44526C;
        }
    }
    .grid-3-2 {
        display: grid;
        grid-template-columns: 4fr 3fr;
        gap: 24px;
    }
    .title-area {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
    }
    .card {
        padding: 0;
        margin: 0;

        &.graph {
            display: flex;
            flex-direction: row;
            .left {
                width: 100%;
                border-right: 1px solid #F4F5F7;
            }
            .right {
                width: 330px;
                padding-left: 40px;
                display: flex;
                align-items: center;
                padding-bottom: 54px;
                .legend {
                    width: 100%;
                    margin-right: 34px;
                }
                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }
                p {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 11px;
                    margin: 0;
                    color: #283856;
                }

                span {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;

                    /* Green/G5 */

                    color: #5DB082;
                }
            }
        }

        canvas {
            max-height: 500px;
        }

        #trafficChart {
            padding: 20px;
        }

        .top {
            padding: 26px 32px;
            padding-bottom: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .form-control, .form-group{
                margin-bottom: 0;
            }
            select {
                height: 32px;
                font-weight: normal;
                font-size: 12px;
                line-height: 11px;
                width: 260px;
                padding-left: 10px;
                border: 1px solid #eceef0;
            }
        }
        .card-title {
            font-weight: 500;
            font-size: 16px;
            margin: 0;
        }
        .header {
            padding: 0 32px;
            font-weight: 400;
            font-size: 10px;
            color: #98A0AE;
        }
        .show-more {
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #0F75BC;
                margin: 0;
            }
        }
        .header {
            gap: 10px;
            display: grid;
            span{
                font-weight: 400;
                font-size: 10px;
                color: #98A0AE;
                text-transform: uppercase;
            }
        }
        .line {
            display: grid;
            align-items: center;
            padding: 0 32px;
            height: 52px;
            border-bottom: 1px solid #EBECF0;
            gap: 10px;
            &.no-data {
                font-weight: 500;
                font-size: 15px;
                color: #98A0AE;
            }
            &:last-child{
                border-bottom: none
            }
            p{
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }
            .img-name {
                display: flex;
                align-items: center;
                gap: 16px;
                img {
                    width: 28px;
                    height: 28px;
                }
                p{
                    margin: 0;
                    font-weight: bold;
                    font-size: 13px;
                }
            }
        }
        &.long{
            grid-column-start: 1;
            grid-column-end: 3;
            .header{
                grid-template-columns: 6fr 2fr 3fr 3fr 3fr;
                span{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    &.name{
                        padding-left: 44px;
                    }
                }
            }
            .filter-down, .filter-up{
                cursor: pointer;
            }
            .filter-up{
                filter: grayscale(1);
                img{
                    transform: rotate(180deg);
                }
            }
            .lines {
                height: 300px;
                overflow-y: auto;
                .line {
                    grid-template-columns: 6fr 2fr 3fr 3fr 3fr;
                }
            }
        }
        &.users {
            .header {
                grid-template-columns: 6fr 1fr 2fr 2fr;
                .name{
                    padding-left: 44px;
                }
            }
            .lines {
                height: 208px;
                overflow-y: auto;
                .line {
                    grid-template-columns: 6fr 1fr 2fr 2fr;
                    gap: 10px;
                    display: grid;
                    align-items: center;
                    padding: 0 32px;
                    height: auto;
                    min-height: 52px;
                }
            }
        }
        &.login-by-usr{
            .header {
                grid-template-columns: 3fr 2fr 3fr;
            }
            .lines {
                height: 250px;
                overflow-y: auto;
                .line {
                    grid-template-columns: 3fr 2fr 3fr;
                    gap: 10px;
                    display: grid;
                    align-items: center;
                    padding: 0 32px;
                    height: auto;
                    min-height: 52px;
                    p.name{
                        margin: 0;
                        font-weight: bold;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

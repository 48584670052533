.manage-users{
    table, .table{
        margin-top: 10px;
        color: #44526C;
        font-size: 14px;
        a, button.btn{
            border-radius: 42px;
            font-weight: 500;
            width: 100px;
            height: 34px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        th{
            border-top: none;
        }
    }
    .table th, .table td{
        vertical-align: middle;
        padding: 0.75rem 0;
        form{
            margin: 0;
        }
    }
    .table thead th{
        border-bottom: none;
    }
}

.import-usr-section{
    margin-top: 20px;
    form{
        display: flex;
        gap: 20px;
        align-items: center;
        margin-top: 27px;
    }
    button{
        font-weight: 500;
        width: 120px;
        height: 50px;
        padding: 0;
        align-items: center;
        justify-content: center;
        border: 1px solid silver;
    }
}

.inf__drop-area{
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    width: 450px;
    max-width: 100%;
    border: 1px dashed #c4c4c4;
    border-radius: 3px;
    padding: 0 20px;
    transition: 0.2s;
}
.inf__btn {
    display: inline-block;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    padding: 5px 12px;
    margin-right: 10px;
    font-size: 10px;
    text-transform: uppercase;
}
.inf__hint {
    flex: 1;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.user-details{
    margin-top: 18px;
    button.btn{
        border-radius: 42px;
        font-weight: 500;
        width: 100px;
        height: 34px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    legend{
        font-size: 17px;
    }
    label{
        margin-bottom: 2px;
    }
    .checkboxes{
        .form-group{
            margin-bottom: 5px;
        }
        label{
            position: relative;
            top: -1px;
            margin-left: 5px;
        }
    }
    .group{
        label{
            position: relative;
            top: -1px;
            margin-left: 5px;
        }
    }
}

.users-exists{
    table, .table{
        margin-top: 10px;
        color: #44526C;
        font-size: 14px;
        th{
            border-top: none;
        }
    }
    .table th, .table td{
        vertical-align: middle;
        padding: 0.75rem 0;
    }
    .table thead th{
        border-bottom: none;
    }
    a.btn{
        border-radius: 42px;
        font-weight: 500;
        width: 160px;
        height: 34px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
}
// new invite
.new-invite{
    max-width: 400px;
    margin: 0 auto;
    margin-top: 50px;
    .form-control{
        margin-bottom: 10px;
    }
    h4{
        font-size: 18px;
        margin: 14px 0;
        margin-bottom: 15px;
    }
    button.btn{
        border-radius: 42px;
        font-weight: 500;
        width: 100px;
        height: 34px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
}